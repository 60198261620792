<template>
  <v-card flat tile class="border-light-grey ">
    <v-card-title class="headline grey lighten-4">
      <v-layout>
        <v-flex>
          <h3 class="custom-sub-header-blue-text">Promotions</h3>
        </v-flex>
        <v-flex class="text-right">
          <v-btn
            :disabled="pageLoading"
            :loading="pageLoading"
            class="white--text mr-2"
            depressed
            color="blue darken-4"
            tile
            v-on:click="addPromotion()"
          >
            New Promotion
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card-title>
    <v-progress-linear
      indeterminate
      height="6"
      v-if="pageLoading"
      class="position-absolute"
      color="blue darken-4"
    ></v-progress-linear>
    <v-card-text class="p-6">
      <v-row>
        <v-col md="6" class="py-0">
          <TextInput
            clearable
            hide-details
            :disabled="pageLoading"
            :loading="pageLoading"
            id="promotion-search"
            placeholder="Search by Promotion"
            v-model="search"
            v-on:click:clear="getPromotions()"
            v-on:keydown:esc="getPromotions()"
            v-on:keydown:enter="getPromotions()"
            v-on:keydown:tab="getPromotions()"
          ></TextInput>
        </v-col>
      </v-row>
      <v-simple-table class="bt-table mt-4">
        <template v-slot:default>
          <thead>
            <tr>
              <th width="100">Actions</th>
              <th width="50">Promotion #</th>
              <th width="300">Promotion</th>
              <th width="100">Expiry Date</th>
              <th width="50">Used/Limit</th>
              <th width="50">Claim Amount</th>
              <th width="50">Min Order Amount</th>
              <th width="200">Created At</th>
              <th width="200">Last Modified At</th>
            </tr>
          </thead>
          <tbody v-if="!pageLoading">
            <template v-if="promotions.length">
              <tr v-for="(row, index) in promotions" :key="index">
                <td width="100">
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        depressed
                        fab
                        dark
                        x-small
                        color="green"
                        v-bind="attrs"
                        v-on="on"
                        class="mr-1"
                        v-on:click="updatePromotion(row)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        depressed
                        fab
                        dark
                        x-small
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                        class="ml-1"
                        v-on:click="deleteConfirm(row)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </td>
                <td width="50"><Chip :text="row.barcode"></Chip></td>
                <td width="300">
                  <v-layout>
                    <v-flex md4>
                      <ImageTemplate
                        circle
                        :src="row.image"
                        custom-class="border-light-grey max-width-100px"
                      ></ImageTemplate>
                    </v-flex>
                    <v-flex class="ml-4 my-auto">
                      <label class="btx-label text-truncate">{{ row.name }}</label>
                    </v-flex>
                  </v-layout>
                </td>
                <td width="100">
                  <template v-if="row.expiry_date">{{ formatDate(row.expiry_date) }}</template
                  ><em class="text--secondary" v-else>N/A</em>
                </td>
                <td width="50">{{ row.used }}/{{ row.limit }}</td>
                <td width="50">{{ formatCurrency(row.claim_amount) }}</td>
                <td width="50">{{ formatCurrency(row.min_order_amount) }}</td>
                <td width="200">{{ formatDateTime(row.added_at) }}</td>
                <td width="200">
                  <template v-if="row.updated_at">{{ formatDateTime(row.updated_at) }}</template
                  ><em class="text--secondary" v-else>N/A</em>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="8">
                  <p class="m-0 text-center">
                    <img
                      width="30"
                      :src="$assetURL('media/error/empty.png')"
                      class="row-not-found-image mr-4"
                    />
                    Uhh... There are no promotion at the moment.
                  </p>
                </td>
              </tr>
            </template>
          </tbody>
          <tfoot v-else>
            <tr v-for="idr in 8" :key="idr">
              <td v-for="idk in 8" :key="idk">
                <v-skeleton-loader type="text"></v-skeleton-loader>
              </td>
            </tr>
          </tfoot>
        </template>
      </v-simple-table>
      <v-layout v-if="promotions.length" class="light-border-top mt-4">
        <v-flex md6>
          <label class="btx-label p-4"
            >Showing {{ showingFrom }} to {{ showingTo }} of {{ totalRows }} entries</label
          >
        </v-flex>
        <v-flex md6>
          <v-pagination
            color="blue darken-4"
            v-model="currentPage"
            :length="pageLimit"
            :total-visible="7"
          ></v-pagination>
        </v-flex>
      </v-layout>
    </v-card-text>
    <Dialog :dialog="dialog">
      <template v-slot:title>
        {{ dialogTitle }}
      </template>
      <template v-slot:body>
        <v-container fluid class="pt-0">
          <v-form
            ref="promotionForm"
            v-model.trim="formValid"
            lazy-validation
            v-on:submit.stop.prevent="updateOrCreate()"
          >
            <v-row>
              <v-col md="12">
                <v-layout>
                  <v-flex md4 class="my-auto">
                    <label for="promotion-name" class="btx-label mt-2 required"
                      >Promotion Name</label
                    >
                  </v-flex>
                  <v-flex md8>
                    <TextInput
                      hide-details
                      :rules="[vrules.required(promotion.name, 'Promotion Name')]"
                      :class="{ required: !promotion.name }"
                      v-model="promotion.name"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      id="promotion-name"
                      placeholder="Promotion Name"
                    ></TextInput>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex md4>
                    <label for="promotion-description" class="btx-label mt-2">Description</label>
                  </v-flex>
                  <v-flex md8>
                    <TextAreaInput
                      hide-details
                      v-model="promotion.description"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      id="promotion-description"
                      placeholder="Description"
                    ></TextAreaInput>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex md4 class="my-auto">
                    <label for="claim-amount" class="btx-label mt-2">Claim Amount</label>
                  </v-flex>
                  <v-flex md8>
                    <CurrencyInput
                      hide-details
                      v-model="promotion.claim_amount"
                      :rules="[vrules.required(promotion.claim_amount, 'Claim Amount')]"
                      :class="{ required: !promotion.claim_amount }"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      id="claim-amount"
                      placeholder="Claim Amount"
                    ></CurrencyInput>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex md4 class="my-auto">
                    <label for="minimum-order-amount" class="btx-label mt-2"
                      >Min Order Amount</label
                    >
                  </v-flex>
                  <v-flex md8>
                    <CurrencyInput
                      hide-details
                      v-model="promotion.min_order_amount"
                      :rules="[vrules.required(promotion.min_order_amount, 'Min Order Amount')]"
                      :class="{ required: !promotion.min_order_amount }"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      id="minimum-order-amount"
                      placeholder="Min Order Amount"
                    ></CurrencyInput>
                  </v-flex>
                </v-layout>
                <v-layout v-if="!promotionId">
                  <v-flex md4 class="my-auto">
                    <label for="limit" class="btx-label mt-2">Limit</label>
                  </v-flex>
                  <v-flex md8>
                    <NumberInput
                      hide-details
                      v-model="promotion.limit"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      id="limit"
                      placeholder="Limit"
                    ></NumberInput>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex md4 class="my-auto">
                    <label for="expiry-date" class="btx-label mt-2">Expiry Date</label>
                  </v-flex>
                  <v-flex md8>
                    <DatePicker
                      clearable
                      hide-details
                      v-model="promotion.expiry_date"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      id="expiry-date"
                      placeholder="Expiry Date"
                    ></DatePicker>
                  </v-flex>
                </v-layout>
              </v-col>
              <v-col md="12">
                <v-layout>
                  <v-flex md4 class="my-auto">
                    <label for="promotion-image" class="btx-label mt-2">Image</label>
                  </v-flex>
                  <v-flex md8>
                    <v-row class="item-images">
                      <v-col md="4" v-for="(image, ind) in images" :key="ind">
                        <ImageTemplate
                          :src="promotion.image"
                          :key="ind"
                          custom-class="border-light-grey"
                        ></ImageTemplate>
                      </v-col>
                      <v-col md="4">
                        <v-file-input
                        multple
                          accept="image/png, image/jpeg, image/bmp"
                          ref="fileInput"
                          class="d-none"
                          v-on:change="handleFilesUpload()"
                        >
                        </v-file-input>
                        <v-tooltip top content-class="custom-top-tooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              :disabled="pageLoading"
                              :loading="pageLoading"
                              v-bind="attrs"
                              v-on="on"
                              icon
                              color="blue darken-4"
                              depressed
                              class="upload-button"
                              tile
                              v-on:click="fileInput()"
                            >
                              <v-icon class="upload-icon d-contents"
                                >mdi-cloud-upload-outline</v-icon
                              >
                            </v-btn>
                          </template>
                          <span> Upload Image </span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-flex>
                </v-layout>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="pageLoading || !formValid"
          :loading="pageLoading"
          class="white--text mr-2"
          depressed
          color="blue darken-4"
          tile
          v-on:click="updateOrCreate()"
        >
          Save
        </v-btn>
        <v-btn :disabled="pageLoading" depressed tile v-on:click="clearVars()"> Cancel </v-btn>
      </template>
    </Dialog>
    <DeleteTemplate
      type="promotion"
      :delete-text="deleteText"
      delete-note="All transactions of this promotion will also be deleted."
      :delete-dialog="deleteDialog"
      :delete-endpoint="deleteEndpoint"
      v-on:close="deleteDialog = false"
      v-on:delete:success="getPromotions"
    ></DeleteTemplate>
  </v-card>
</template>

<script>
import Chip from "@/view/components/Chip";
import TextInput from "@/view/components/TextInput";
import ImageTemplate from "@/view/components/Image";
import NumberInput from "@/view/components/NumberInput";
import DatePicker from "@/view/components/DatePicker";
import CurrencyInput from "@/view/components/CurrencyInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import Dialog from "@/view/components/Dialog";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { UPDATE_FILES } from "@/core/lib/upload.lib";
import { GET_PROMOTIONS, CREATE_PROMOTION, UPDATE_PROMOTION } from "@/core/lib/common.lib";
import { head } from "lodash";

export default {
  data() {
    return {
      search: null,
      deleteDialog: false,
      deleteEndpoint: null,
      deleteText: null,
      dialog: false,
      formValid: true,
      currentPage: 1,
      pageLimit: 1,
      showingFrom: 0,
      showingTo: 0,
      totalRows: 0,
      dialogTitle: "Add Promotion",
      pageLoading: true,
      promotionId: null,
      speedDial: [],
      images: [],
      promotions: [],
      promotion: {
        name: null,
        description: null,
        image: null,
        limit: null,
        expiry_date: null,
        claim_amount: null,
        min_order_amount: null,
      },
    };
  },
  watch: {
    currentPage() {
      this.getPromotions();
    },
  },
  methods: {
    deleteConfirm(row) {
      this.deleteDialog = true;
      this.deleteText = row.barcode;
      this.deleteEndpoint = `promotion/${row.id}`;
    },
    updatePromotion(row) {
      this.dialog = true;
      this.promotionId = row.id;
      this.dialogTitle = `Update Promotion | ${row.barcode}`;
      this.promotion = {
        name: row.name,
        description: row.description,
        image: row.image,
        limit: row.limit,
        expiry_date: row.expiry_date,
        claim_amount: row.claim_amount,
        min_order_amount: row.min_order_amount,
      };
      this.images = [{ url: row.image }];
    },
    addPromotion() {
      this.clearVars();
      this.dialog = true;
    },
    clearVars() {
      this.dialog = false;
      this.promotionId = null;
      this.dialogTitle = "Add Promotion";
      this.images = [];
      this.deleteDialog = false;
      this.deleteText = null;
      this.deleteEndpoint = null;
      this.promotion = {
        name: null,
        description: null,
        image: null,
        limit: null,
        expiry_date: null,
        claim_amount: null,
        min_order_amount: null,
      };
    },
    async updateOrCreate() {
      const _this = this;

      if (!_this.$refs.promotionForm.validate()) {
        return false;
      }

      try {
        _this.pageLoading = true;

        if (_this.promotionId) {
          await UPDATE_PROMOTION(_this.promotionId, _this.promotion);
          _this.clearVars();
          _this.getPromotions();
          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Promotion Updated Successfully." },
          ]);
        } else {
          // console.log(_this.promotion)
          await CREATE_PROMOTION(_this.promotion);
          _this.clearVars();
          _this.getPromotions();
          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Promotion Created Successfully." },
          ]);
        }
      } catch (error) {
        _this.$store.commit(SET_ERROR, [
          { model: true, message: "Something went wrong. Please try again later" },
        ]);
      } finally {
        _this.pageLoading = false;
      }
    },
    async getPromotions() {
      try {
        const form = {
          current_page: this.currentPage,
          search: this.search,
        };
        this.pageLoading = true;
        const { rows, totalPages, showingFrom, showingTo, totalRows } = await GET_PROMOTIONS(form);
        this.promotions = rows;
        this.pageLimit = totalPages;
        this.showingFrom = showingFrom;
        this.showingTo = showingTo;
        this.totalRows = totalRows;
        this.clearVars();
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
    async handleFilesUpload() {
      try {
        const { fileInput } = this.$refs;
        if (fileInput && fileInput.$refs) {
          const { input } = fileInput.$refs;
          if (input && input.files) {
            if (input.files.length) {
              const formData = new FormData();
              for (let i = 0; i < input.files.length; i++) {
                formData.append(`files[${i}]`, input.files[i]);
                break;
              }
              this.pageLoading = true;
              const files = await UPDATE_FILES(formData);

              const image = head(files);
              this.images = [image];

              this.promotion.image = image.file.url;
            }
          }
        }
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
    fileInput() {
      const { fileInput } = this.$refs;
      if (fileInput && fileInput.$refs) {
        const { input } = fileInput.$refs;
        if (input) {
          input.click();
        }
      }
    },
  },
  mounted() {
    this.getPromotions();
    this.$store.dispatch(SET_BREADCRUMB, [
      { text: "Setting", disabled: true },
      { text: "Promotions", disabled: true },
    ]);
  },
  beforeDestroy() {
    this.$store.dispatch(SET_BREADCRUMB, []);
  },
  components: {
    Chip,
    Dialog,
    DeleteTemplate,
    ImageTemplate,
    DatePicker,
    NumberInput,
    CurrencyInput,
    TextAreaInput,
    TextInput,
  },
};
</script>
