<template>
    <v-card flat tile class="border-light-grey mt-4">
        <v-card-title class="headline grey lighten-4">


            <v-layout>

                <v-flex class="text-right">
                    <v-btn :disabled="pageLoading" :loading="pageLoading" class="white--text mr-2" depressed
                        color="blue darken-4" tile v-on:click="openDialog">
                        Add
                    </v-btn>
                    <v-btn :disabled="pageLoading" :loading="pageLoading" class="white--text mr-2" depressed
                        color="blue darken-4" tile v-on:click="openSubDialog">
                        Add-SubCategory
                    </v-btn>
                </v-flex>
            </v-layout>
        </v-card-title>
        <v-progress-linear indeterminate height="6" v-if="pageLoading" class="position-absolute"
            color="blue darken-4"></v-progress-linear>
        <v-card-text class="p-6">
            <v-row>

                <!-- <v-col md="2" class="py-0 pt-2">
            <SettingHeader moduleType="purchaseorder" :dataLoading="dataLoading" :status-list="departmentCategory"
              allkey="all_purchase" countkey="status_count" v-on:saveStatus="updateStatus"></SettingHeader>
          </v-col> -->
                <v-col md="2" class="py-0">
                    <TextInput clearable hide-details :disabled="pageLoading" :loading="pageLoading" id="category-search"
                        placeholder="Search " v-model="search" v-on:click:clear="getDepartments()"
                        v-on:keydown:esc="getDepartments()" v-on:keydown:enter="getDepartments()"
                        v-on:keydown:tab="getDepartments()"></TextInput>
                </v-col>
            </v-row>
            <v-simple-table class="bt-table mt-4">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th width="50">Action</th>
                            <th>Category</th>
                            <th width="200">Created At</th>
                            <th width="200">Last Modified At</th>
                        </tr>
                    </thead>
                    <tbody v-if="departmentCategory?.length">
                        <tr class="cursor-pointer" v-for="(cat, ind) in departmentCategory" :key="ind"
                            v-on:click="updateCategory(cat)">

                            <td width="100">
                                <v-tooltip top content-class="custom-top-tooltip">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn depressed fab dark x-small color="green" v-bind="attrs" v-on="on"
                                            class="mr-1" v-on:click="updateVoucher(cat)">
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Edit</span>
                                </v-tooltip>
                                <!-- <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn depressed fab dark x-small color="red" v-bind="attrs" v-on="on" class="ml-1"
                        v-on:click="deleteConfirm(cat)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete</span>
                  </v-tooltip> -->
                            </td>
                            <td width="300">{{ cat.name }}</td>

                            <td width="200">{{ formatDateTime(cat.added_at) }}</td>
                            <td width="200">
                                <template v-if="cat.updated_at">{{ formatDateTime(cat.updated_at) }}</template><em
                                    class="text--secondary" v-else>N/A</em>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="4">
                                <p class="m-0 text-center">
                                    <img width="30" :src="$assetURL('media/error/empty.png')"
                                        class="row-not-found-image mr-4" />
                                    Uhh... There are no category at the moment.
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <v-layout v-if="departmentCategory?.length" class="light-border-top mt-4">
                <v-flex md6>
                    <label class="btx-label p-4">Showing {{ showingFrom }} to {{ showingTo }} of {{ total }} entries</label>
                </v-flex>
                <v-flex md6>
                    <v-pagination color="blue darken-4" v-model="currentPage" :length="totalPages"
                        total-visible="7"></v-pagination>
                </v-flex>
            </v-layout>
        </v-card-text>
        <Dialog :dialog="dialog">
            <template v-slot:title>
                <v-layout>
                    <v-flex>Budget Categories </v-flex>
                    <v-flex class="text-right">
                        <v-btn :disabled="pageLoading" :loading="pageLoading" class="white--text" depressed
                            color="blue darken-4" tile v-on:click="addCategory()">
                            <v-icon left>mdi-plus</v-icon>
                            Add...
                        </v-btn>
                    </v-flex>
                </v-layout>
            </template>
            <template v-slot:body>
                <v-container fluid class="pt-0">
                    <template v-for="(row, ind) in categories">
                        <TextInput :key="ind" hide-details :disabled="pageLoading" :loading="pageLoading"
                            :id="`category-${ind}`" placeholder="budget category" v-on:keypress="(e) => manageLimit(e,ind)" v-on:paste="(e) => onPaste(e,ind)" v-model="row.name"
                            :append-outer-icon="categories?.length > 1 ? `mdi-delete` : ''" class="has-delete-outer"
                            v-on:click:append-outer="removeCategory(ind)"></TextInput>
                    </template>
                </v-container>
            </template>
            <template v-slot:action>
                <v-btn :disabled="pageLoading" :loading="pageLoading" class="white--text mr-2" depressed
                    color="blue darken-4" tile v-on:click="updateCategories()">
                    Save
                </v-btn>
                <v-btn :disabled="pageLoading" depressed tile v-on:click="dialog = false;"> Cancel </v-btn>
            </template>
        </Dialog>
        <Dialog :dialog="editdialog">
            <template v-slot:title>
                <v-layout>
                    <v-flex>Edit Category</v-flex>

                </v-layout>
            </template>
            <template v-slot:body>
                <v-container fluid class="pt-0">
                    <template>
                        <TextInput hide-details :disabled="pageLoading" :loading="pageLoading"
                            :id="`category-${deptcategory.id}`" v-on:keypress="(e) => manageLimits(e)" v-on:paste="(e) => onPastes(e)" placeholder="budget category"
                            v-model="deptcategory.name"></TextInput>
                    </template>
                </v-container>
            </template>
            <template v-slot:action>
                <v-btn :disabled="pageLoading" :loading="pageLoading" class="white--text mr-2" depressed
                    color="blue darken-4" tile v-on:click="updateDeptCategories()">
                    Save
                </v-btn>
                <v-btn :disabled="pageLoading" depressed tile v-on:click="editdialog = false;"> Cancel </v-btn>
            </template>
        </Dialog>
        <Dialog dense :dialog="subCategoryDialog">
            <template v-slot:title>
                {{ dialogTitle }}
            </template>
            <template v-slot:body>
                <v-container fluid class="pt-0">
                    <v-form ref="voucherForm" v-model.trim="formValid" lazy-validation
                        v-on:submit.stop.prevent="updateOrCreate()">
                        <v-row>
                            <v-col md="12">
                                <v-layout>
                                    <v-flex md4 class="my-auto">
                                        <label for="voucher-name" class="btx-label mt-2 required"> Category</label>
                                    </v-flex>
                                    <v-flex md8>
                                        <AutoCompleteInput hide-details :hideTopMargin="true"
                                        :rules="[vrules.required(voucher.department, 'supplier')]"
                                        :class="{ required: !voucher.department }" :items="departmentCategory"
                                            id="segment" item-text="name" style="width: 500px" item-value="id"
                                            v-model="voucher.department" placeholder="Select Category">
                                        </AutoCompleteInput>
                                        <!-- <TextInput
                        hide-details
                       
                        :class="{ required: !voucher.name }"
                        v-model="voucher.name"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        id="voucher-name"
                        placeholder="Voucher Name"
                      ></TextInput> -->
                                    </v-flex>
                                </v-layout>
                                <v-layout>
                                    <v-flex md4 class="my-auto">
                                        <label for="voucher-name" class="btx-label mt-2 required"> Name</label>
                                    </v-flex>
                                    <v-flex md8>
                                        <template v-for="(row, ind) in sub_categories">
                                            <div class="d-flex align-center" :key="ind">
                                                <TextInput hide-details :disabled="pageLoading" :loading="pageLoading"
                                                    :id="`voucher-${ind}`" placeholder="name"  v-on:keypress="(e) => manageLimitSub(e,ind)" v-on:paste="(e) => onPasteSub(e,ind)"
                                                    v-model="row.name" :rules="[vrules.required(row.name, 'supplier')]"
                                        :class="{ required: !row.name }" v-on:click:append-outer="addSubCategory(ind)"
                                                    class="has-delete-outer"></TextInput>

                                                <v-btn v-if="sub_categories.length > 1" class="mx-1 mt-3"
                                                    :id="`voucher-${ind}`" v-on:click="removSubCategory(ind)" fab dark
                                                    x-small color="red">
                                                    <v-icon dark>
                                                        mdi-minus
                                                    </v-icon>
                                                </v-btn>

                                                <v-btn class="mx-1 mt-3" fab dark :id="`voucher-${ind}`"
                                                    v-on:click="addSubCategory(ind)" x-small color="primary">
                                                    <v-icon dark>
                                                        mdi-plus
                                                    </v-icon>
                                                </v-btn>
                                            </div>
                                        </template>

                                    </v-flex>
                                </v-layout>

                            </v-col>

                        </v-row>
                    </v-form>
                </v-container>
            </template>
            <template v-slot:action>
                <v-btn :disabled="pageLoading || !formValid" :loading="pageLoading" class="white--text mr-2" depressed
                    color="blue darken-4" tile v-on:click="updateOrCreate()">
                    Save
                </v-btn>
                <v-btn :disabled="pageLoading" depressed tile v-on:click="subCategoryDialog = false;"> Cancel </v-btn>
            </template>
        </Dialog>

        <DeleteTemplate type="voucher" :delete-text="deleteText"
            delete-note="All transactions of this voucher will also be deleted." :delete-dialog="deleteDialog"
            :delete-endpoint="deleteEndpoint" v-on:close="deleteDialog = false" v-on:delete:success="getDepartments">
        </DeleteTemplate>


    </v-card>
</template>
  
<script>
import { toSafeInteger } from "lodash";

//   import SettingHeader from "@/view/components/SettingHeader";
import { QUERY } from "@/core/services/store/request.module";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import TextInput from "@/view/components/TextInput";
import DeleteTemplate from "@/view/components/DeleteTemplate";

import Dialog from "@/view/components/Dialog";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { UPDATE_BUDGETCATEGORY, CREATE_SUBBUDGET, UPDATE_BUDCATEGORY } from "@/core/lib/common.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
// import SubCategoryTemplate from "@/view/module/setting/SubCategoryTemplate";
import { RefressBus } from "@/core/event-bus/refress.bus.js";

export default {
    props: {
    title: {
      type: Object,
      default: () => {
        return [];
      },
    },
  },
    data() {
        return {
            search: null,
            selectstatus: null,
            currentPage: 1,
            pageLimit: 1,
            showingFrom: 0,
            showingTo: 0,
            totalRows: 0,
            deleteDialog: false,
            deleteEndpoint: null,
            deleteText: null,
            dialogTitle: "Add Budget SubCategory",
            subCategory: [],
            departmentCategory: [],
            dialog: false,
            editdialog: false,
            dataLoading: false,
            formValid: false,
            subCategoryDialog: false,
            voucher: {
                name: null,
                department: null

            },
            deptcategory: {
                name: null,
                id: null

            },
            pageLoading: false,
            category: 0,
            categoryName: null,
            categories: [{ name: null }],
            sub_categories: [{ name: null }],

        };
    },
    methods: {
        onPasteSub(e,index) {
            let coppied = e.clipboardData.getData("Text");
            let preVal =  this.sub_categories[index].name;
            let finalval = String(preVal)+ String(coppied);
            if (Number(finalval.length) > 29) {
                let trimValue = finalval.substring(0, 30);
                    this.sub_categories[index].name =trimValue;
                    e.preventDefault();
            }
        },
        manageLimitSub(e,index) {
                if (this.sub_categories[index].name &&
                    this.sub_categories[index].name.length > 29) {
                    e.preventDefault();
                }  

        },


        onPaste(e,index) {
            let coppied = e.clipboardData.getData("Text");
            let preVal =  this.categories[index].name;
            let finalval = String(preVal)+ String(coppied);
            if (Number(finalval.length) > 29) {
                let trimValue = finalval.substring(0, 30);
                    this.categories[index].name =trimValue;
                    e.preventDefault();
            }
        },

        manageLimit(e,index) {
                if (this.categories[index].name &&
                    this.categories[index].name.length > 29) {
                    e.preventDefault();
                }  

        },
        manageLimits(e) {
      if (
        this.deptcategory.name &&
        this.deptcategory.name.length > 29
      ) {
        e.preventDefault();
      }
    },
    onPastes(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.deptcategory.name;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 29) {
        let trimValue = finalval.substring(0, 30);
        this.deptcategory.name = trimValue;
        e.preventDefault();
      }
    },


        updateVoucher(row) {

            this.subCategoryDialog = false;
            console.log(row, 'row')

            this.deptcategory.id = row?.id;
            this.deptcategory.name = row?.name;

        },

        updateStatus(param) {
            this.selectstatus = param;
            console.log(this.selectstatus)
            this.getDepartments();
        },
        openDialog() {
            this.categories = [{ name: null }];
            this.dialog = true;
        },
        openSubDialog() {
             this.sub_categories = [{name: null}];
             this.voucher.department=0;

            this.subCategoryDialog = true;
        },
        clearVars() {
            this.subCategoryDialog = false;


        },
        updateCategory({ id, name }) {
            this.category = id;
            this.categoryName = name;
            this.editdialog = true;
        },
        addCategory() {

            this.categories.push({
                name: null,
            });
        },
        addSubCategory() {
            this.sub_categories.push({
                name: null,
            });
        },
        removSubCategory(index) {
            this.sub_categories.splice(index, 1);
            if (this.sub_categories.length <= 0) {
                this.addSubCategory();
            }
        },
        removeCategory(index) {
            this.categories.splice(index, 1);
            if (this.categories.length <= 0) {
                this.addCategory();
            }
        },
        getDepartments() {
            console.log(this.search, 'search')


            const _this = this;
            _this.pageLoading = true;
            this.$store
                .dispatch(QUERY, {
                    url: `budget-categorys`,
                    data: {
                        search: this.selectstatus,
                        current_page: this.currentPage,
                        filter: this.search,

                    },
                })
                .then((data) => {
                    this.sub_categories = [{ name: null }];
                    _this.departmentCategory = data.rows;
                    this.currentPage = data.current_page;
                    this.showingFrom = data.showingFrom;
                    this.showingTo = data.showingTo;
                    this.total = data.totalrows;
                    this.totalPages = data.totalPages;


                    this.addCategory();


                })
                .catch((error) => {
                    this.logError(error);
                })
                .finally(() => {
                    this.timerLoading = false;
                    _this.pageLoading = false;
                    _this.dialog = false;
                    _this.editdialog = false;
                    _this.subCategoryDialog = false;


                });

        },
        async updateDeptCategories() {
            const _this = this;
            try {
                _this.pageLoading = true;


                //   for (let i = 0; i < this.categories.length; i++) {
                //     // if (this.categories && this.categories[i] && this.categories[i].file) {
                //     // 	formData.append(`file[${i}][file]`, this.categories[i].file);
                //     // }
                //     if (this.categories && this.categories[i] && !this.categories[i].name) {
                //       _this.$store.commit(SET_ERROR, [{ model: true, message: 'This field is required' }]);
                //       return false;

                //     }
                //   }
                console.log(_this.deptcategory, 'params')
                await UPDATE_BUDCATEGORY(_this.deptcategory);
                _this.getDepartments();
                _this.dialog = false;
                _this.$store.commit(SET_MESSAGE, [
                    { model: true, message: "Category Updated Successfully" },
                ]);
            } catch (error) {
                _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
            } finally {
                _this.pageLoading = false;

            }
        },


        async updateCategories() {
            const _this = this;
            try {
                _this.pageLoading = true;


                for (let i = 0; i < this.categories.length; i++) {
                    // if (this.categories && this.categories[i] && this.categories[i].file) {
                    // 	formData.append(`file[${i}][file]`, this.categories[i].file);
                    // }
                    if (this.categories && this.categories[i] && !this.categories[i].name) {
                        _this.$store.commit(SET_ERROR, [{ model: true, message: 'This field is required' }]);
                        return false;

                    }
                }
                await UPDATE_BUDGETCATEGORY({ options: _this.categories });
                _this.getDepartments();
                _this.dialog = false;
                _this.$store.commit(SET_MESSAGE, [
                    { model: true, message: " Category created Successfully" },
                ]);
            } catch (error) {
                _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
            } finally {
                _this.pageLoading = false;
            }
        },
        async updateOrCreate() {
            const _this = this;

            if (!_this.$refs.voucherForm.validate()) {
                return false;
            }
            console.log(this.voucher.department)
            console.log(_this.sub_categories, 'vouvher')


            try {
                _this.pageLoading = true;
                if (_this.voucherId) {
                    await CREATE_SUBBUDGET(_this.voucherId, _this.voucher);
                    _this.clearVars();
                    _this.$store.commit(SET_MESSAGE, [
                        { model: true, message: "Voucher Updated Successfully." },
                    ]);
                } else {
                    await CREATE_SUBBUDGET(_this.voucher?.department, { options: _this.sub_categories });
                    _this.sub_categories = [{ name: null }],
                        _this.getDepartments();
                    _this.clearVars();

                    _this.$store.commit(SET_MESSAGE, [
                        { model: true, message: "Sub-Budget Created Successfully." },
                    ]);
                }
            } catch (error) {
                _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
            } finally {
                _this.pageLoading = false;
                RefressBus.$emit("data:update", true);

            }
        },
    },
    watch: {

        currentPage() {
            this.getDepartments();
        },
    },


    mounted() {
        this.getDepartments();
        if (this.$route.query && this.$route.query.tab) {
            this.productTab = this.$route.query.tab;
        } else {
            this.$router.replace({ query: { tab: "categories", t: new Date().getTime() } });
        }
        RefressBus.$on("data:update", (argument) => {
            if (argument) {
                this.getDepartments();
            }
        });
    },


    // this.$store.dispatch(SET_BREADCRUMB, [
    //   { text: "Setting", disabled: true },
    //   { text: "Categories", disabled: true },
    // ]);

    beforeDestroy() {
        this.$store.dispatch(SET_BREADCRUMB, []);
    },
    components: {
        Dialog,
        TextInput,
        // SubCategoryTemplate,
        //   SettingHeader,
        AutoCompleteInput,
        DeleteTemplate
    },
    computed: {

        dialogWidth() {
            return toSafeInteger((document.body.clientWidth / 100) * 70);
        },

    },
};
</script>
  