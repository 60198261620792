<template>
  <v-card flat tile class="border-light-grey">
    <v-card-title class="headline grey lighten-4">
      
      <v-row>
  
  <v-col md="12" class="pl-2" color="blue darken-4">
      <v-tabs
          active-class="custom-tab-active"
          v-model="catTypeTab"
          background-color="transparent"
          color="blue darken-4"
          class="custom-tab-transparent tab-sticky"
        >
          <v-tab class="font-size-16 font-weight-600 px-8" href="#category">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
           
            </span>
            Category
          </v-tab>
          <v-tab
            class="font-size-16 font-weight-600 px-8"
            href="#subcategory"
          >
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
           
            </span>
            Sub Category
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="catTypeTab">
          <v-tab-item value="category">
              
            <BudgetCategory title="Budget Categories"></BudgetCategory>


          </v-tab-item>
          <v-tab-item value="subcategory">
          <BudgetSubCategory reload="reload"></BudgetSubCategory>
          </v-tab-item>
        </v-tabs-items>
  </v-col>
  
</v-row>
</v-card-title>
</v-card>
 
</template>

<script>
import { toSafeInteger } from "lodash";

// import SettingHeader from "@/view/components/SettingHeader";
import { QUERY } from "@/core/services/store/request.module";
// import AutoCompleteInput from "@/view/components/AutoCompleteInput";
// import TextInput from "@/view/components/TextInput";

import BudgetSubCategory from "@/view/components/BudgetSubCategory";
import BudgetCategory from "@/view/components/BudgetCategory";


// import Dialog from "@/view/components/Dialog";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { UPDATE_CATEGORY,CREATE_SUBDEPARTMENT } from "@/core/lib/common.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
// import SubCategoryTemplate from "@/view/module/setting/SubCategoryTemplate";

export default {
 
  data() {
    return {
      search: null,
      selectstatus:null,
      // dialogTitle: "Add Department SubCategory",
      subCategory:[],
      catTypeTab: 'category',
      dialog: false,
      dataLoading: false,
      departmentCategory: [],
      formValid:false,
      subCategoryDialog: false,
      voucher: {
        name: null,
        department: null

      },
      pageLoading: false,
      category: 0,
      categoryName: null,
      categories: [{ name: null }],
      sub_categories: [{ name: null }],
      product_tabs: [
          {
            key: "stock-details",
            title: "Stock",
            tooltip: "Stock",
            template:BudgetCategory
          },
         
          {
            key: "quantity-adjustments",
            title: "Stock Details",
            tooltip: "Quantity Adjustment",
            template: BudgetSubCategory,
          },
         
          // {
          //   key: "movement",
          //   title: "Movement",
          //   tooltip: "Movement",
          //   template: ProductQualityAdjustments,
          // },
         
        ],
    };
  },
  methods: {
    updateStatus(param) {
      this.selectstatus = param;
      console.log(this.selectstatus)
      this.getDepartments();
    },
    openDialog() {
      this.categories = [{ name: null }];
      this.dialog = true;
    },
    openSubDialog() {
      // this.categories = [{name: null}];
      this.subCategoryDialog = true;
    },
    clearVars() {
      this.subCategoryDialog = false;


    },
    updateSubCategories({ id, name }) {
      this.category = id;
      this.categoryName = name;
      this.subCategoryDialog = true;
    },
    addCategory() {

      this.categories.push({
        name: null,
      });
    },
    addSubCategory() {
      this.sub_categories.push({
        name: null,
      });
    },
    removSubCategory(index) {
      this.sub_categories.splice(index, 1);
      if (this.sub_categories.length <= 0) {
        this.addSubCategory();
      }
    },
    removeCategory(index) {
      this.categories.splice(index, 1);
      if (this.categories.length <= 0) {
        this.addCategory();
      }
    },
    getDepartments() {
      
      const _this = this;
      _this.pageLoading = true;
      this.$store
        .dispatch(QUERY, {
          url: `department-category`,
          data: {
            search: this.selectstatus,
            
          },
        })
        .then((data) => {
             this.sub_categories = [{ name: null }];
          _this.departmentCategory = data.data;
          _this.subCategory = data.sub_data;
          console.
          this.addCategory();
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
          _this.pageLoading = false;
          _this.dialog = false;
          _this.subCategoryDialog = false;

        });

    },

    // async getCategories() {
    //   const _this = this;
    //   try {
    //     _this.pageLoading = true;
    //     _this.categories = await GET_CATEGORY({ search: this.search });
    //     if (_this.categories.length <= 0) {
    //       _this.addCategory();
    //     }
    //   } catch (error) {
    //     _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
    //   } finally {
    //     _this.pageLoading = false;
    //     _this.dialog = false;
    //   }
    // },
    // async getCategories() {
    //   const _this = this;
    //   try {
    //     _this.pageLoading = true;
    //     _this.categories = await GET_CATEGORY({ search: this.search });
    //     if (_this.categories.length <= 0) {
    //       _this.addCategory();
    //     }
    //   } catch (error) {
    //     _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
    //   } finally {
    //     _this.pageLoading = false;
    //     _this.dialog = false;
    //   }
    // },
    async updateCategories() {
      const _this = this;
      try {
        _this.pageLoading = true;


        for (let i = 0; i < this.categories.length; i++) {
          // if (this.categories && this.categories[i] && this.categories[i].file) {
          // 	formData.append(`file[${i}][file]`, this.categories[i].file);
          // }
          if (this.categories && this.categories[i] && !this.categories[i].name) {
            _this.$store.commit(SET_ERROR, [{ model: true, message: 'This field is required' }]);
            return false;

          }
        }
        await UPDATE_CATEGORY({ options: _this.categories });
        _this.getDepartments();
        _this.dialog = false;
        _this.$store.commit(SET_MESSAGE, [
          { model: true, message: "Department Category created Successfully" },
        ]);
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        _this.pageLoading = false;
      }
    },
    async updateOrCreate() {
      const _this = this;

      if (!_this.$refs.voucherForm.validate()) {
        return false;
      }
      console.log(this.voucher.department)
      console.log(_this.sub_categories, 'vouvher')


      try {
        _this.pageLoading = true;
        if (_this.voucherId) {
          await CREATE_SUBDEPARTMENT(_this.voucherId, _this.voucher);
          _this.clearVars();
          _this.getVouchers();
          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Voucher Updated Successfully." },
          ]);
        } else {
          await CREATE_SUBDEPARTMENT(_this.voucher?.department,{ options: _this.sub_categories });
          _this.sub_categories = [{ name: null }],
          _this.getDepartments();
          _this.clearVars();

          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Sub-Department Created Successfully." },
          ]);
        }
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        _this.pageLoading = false;
      }
    },
  },
  
  
  mounted() {
    this.getDepartments();
    if (this.$route.query && this.$route.query.tab) {
      this.productTab = this.$route.query.tab;
    } else {
      this.$router.replace({ query: { tab: "categories", t: new Date().getTime() } });
    }
  },
    
    // this.$store.dispatch(SET_BREADCRUMB, [
    //   { text: "Setting", disabled: true },
    //   { text: "Categories", disabled: true },
    // ]);
  
  beforeDestroy() {
    this.$store.dispatch(SET_BREADCRUMB, []);
  },
  components: {
    // Dialog,
    // TextInput,
    // SubCategoryTemplate,
    // SettingHeader,
    // AutoCompleteInput,
    BudgetSubCategory,
    BudgetCategory
  },
  computed: {

    dialogWidth() {
      return toSafeInteger((document.body.clientWidth / 100) * 70);
    },
 
  },
};
</script>
